import React, { Suspense, lazy } from "react";
import { Navigate, useRoutes } from "react-router-dom";
import secureLocalStorage from "react-secure-storage";
import { STORAGE_KEY } from "utils/appConstant";

// Lazy load components
const AuthLayout = lazy(() => import("../layout/auth"));
const DashboardLayout = lazy(() => import("../layout/dashboard"));
const Login = lazy(() => import("../pages/auth/login"));
const Otp = lazy(() => import("../pages/auth/otp"));
const ForgotPassword = lazy(() => import("../pages/auth/forgot"));
const ResetPassword = lazy(() => import("../pages/auth/resetPassword"));
const Dashboard = lazy(() => import("../pages/admin/dashboard/index"));
const User = lazy(() => import("../pages/admin/user/index"));
const ProfileDetail = lazy(() => import("../pages/admin/profileDetail/index"));
const NetWorthDetail = lazy(
  () => import("../pages/admin/netWorth/netWorthDetails/index")
);
const LoanRequest = lazy(() => import("../pages/admin/loanRequest/index"));
const CardRequest = lazy(() => import("../pages/admin/cardRequest/index"));
const Transactions = lazy(() => import("../pages/admin/transactions/index"));
const Adds = lazy(() => import("../pages/admin/adds/index"));
const Page404 = lazy(() => import("../pages/page404/index"));
const DownloadApp = lazy(() => import("../pages/admin/downloadApp/index"));
const NetWorth = lazy(() => import("../pages/admin/netWorth/index"));
const TicketManagement = lazy(
  () => import("../pages/admin/ticketManagement/index")
);
const EkycList = lazy(() => import("../pages/admin/eKyc/index"));
const Settings = lazy(() => import("../pages/admin/settings/index"));
const SubscribeUser = lazy(() => import("../pages/admin/subscriberUser/index"));
const CreatePassword = lazy(() => import("pages/admin/createPassword"));
const Notification = lazy(() => import("pages/admin/notification"));
const SendNotification = lazy(
  () => import("pages/admin/notification/sendNotification")
);
const Taxrate = lazy(() => import("pages/admin/taxRate"));
const AssetsDetail = lazy(
  () => import("pages/admin/profileDetail/sections/assetsLiabilities")
);
const BankDetails = lazy(
  () => import("pages/admin/profileDetail/sections/bankDetails")
);
const SetGoalDetails = lazy(
  () => import("pages/admin/profileDetail/sections/setGoalDetails")
);
const EstateDetail = lazy(
  () => import("pages/admin/profileDetail/sections/estate/estateDetails")
);
const EstateDocument = lazy(() => import("pages/admin/estatePlanning"));
const LastWillDocument = lazy(
  () =>
    import(
      "pages/admin/profileDetail/sections/estate/estateDocuments/lastWillDocument"
    )
);
const HippaDocument = lazy(
  () =>
    import(
      "pages/admin/profileDetail/sections/estate/estateDocuments/hipaaDocument"
    )
);
const PowerOfAttorneyDocument = lazy(
  () =>
    import(
      "pages/admin/profileDetail/sections/estate/estateDocuments/powerOfAttorney"
    )
);
const LivingWillDocument = lazy(
  () =>
    import(
      "pages/admin/profileDetail/sections/estate/estateDocuments/livingWill"
    )
);
const RealEstate = lazy(
  () => import("pages/admin/profileDetail/sections/assets/realEstate")
);
const OtherAssets = lazy(
  () => import("pages/admin/profileDetail/sections/assets/other")
);
const Insurance = lazy(
  () => import("pages/admin/profileDetail/sections/assets/insurances")
);
const TermLifeInsurance = lazy(
  () =>
    import(
      "pages/admin/profileDetail/sections/assets/insurances/subComponent/termLifeInsurance"
    )
);
const LongTermInsurance = lazy(
  () =>
    import(
      "pages/admin/profileDetail/sections/assets/insurances/subComponent/longtermInsurance"
    )
);
const IndividualDisability = lazy(
  () =>
    import(
      "pages/admin/profileDetail/sections/assets/insurances/subComponent/individualDisability"
    )
);
const AutoInsurance = lazy(
  () =>
    import(
      "pages/admin/profileDetail/sections/assets/insurances/subComponent/autoInsurance"
    )
);
const UmbrellaInsurance = lazy(
  () =>
    import(
      "pages/admin/profileDetail/sections/assets/insurances/subComponent/umbrella"
    )
);
const WholeLifeInsurance = lazy(
  () =>
    import(
      "pages/admin/profileDetail/sections/assets/insurances/subComponent/wholeLife"
    )
);
const Investment = lazy(
  () => import("pages/admin/profileDetail/sections/assets/investment")
);
const Loans = lazy(
  () => import("pages/admin/profileDetail/sections/liabilities/loans")
);
const OtherLiabilities = lazy(
  () => import("pages/admin/profileDetail/sections/liabilities/other")
);
const ManualAssets = lazy(
  () => import("pages/admin/profileDetail/sections/assets/manual")
);
const AdvertiseManagment = lazy(() => import("pages/admin/advertiseManagment"));
const AddEditAdvertise = lazy(
  () => import("pages/admin/advertiseManagment/addEditAdvertise")
);
const PressRelease = lazy(() => import("pages/admin/pressRelease"));
const AddPressRelease = lazy(
  () => import("pages/admin/pressRelease/addPressRelease")
);
const Notes = lazy(() => import("pages/admin/profileDetail/sections/notes"));
const TransactionList = lazy(
  () => import("pages/admin/profileDetail/sections/transactioList")
);
const CardTransactionList = lazy(
  () => import("pages/admin/profileDetail/sections/cardTranscationList")
);

export default function Router() {
  const authData = secureLocalStorage.getItem(STORAGE_KEY.USER_AUTH);
  const isLoggedIn = authData?.access_token;
  const urlPath = window.location.pathname;

  const routes = useRoutes([
    {
      path: "/",
      element: !isLoggedIn ? <AuthLayout /> : <Navigate to="/login" />,
      children: [
        { path: "login", element: <Login /> },
        { path: "forgot-password", element: <ForgotPassword /> },
        { path: "/", element: <Navigate to="/login" /> },
        { path: "forgotPassword/:type/:id", element: <ResetPassword /> },
        {
          path: "team-member/email-verification/:id",
          element: <CreatePassword />,
        },
        { path: "verify-account", element: <Otp /> },
      ],
    },
    { path: "*", element: <Page404 /> },
    { path: "downloadApp", element: <DownloadApp /> },
    {
      path: "/",
      element:
        isLoggedIn && urlPath.includes("forgotPassword") ? (
          <Navigate to="/forgotPassword/:type/:id" />
        ) : isLoggedIn && !urlPath.includes("forgotPassword") ? (
          <DashboardLayout />
        ) : (
          <Navigate to="/login" />
        ),
      children: [
        { element: <Navigate to="/dashboard" />, index: true },
        { path: "dashboard", element: <Dashboard /> },
        { path: "users", element: <User /> },
        { path: "profileDetail", element: <ProfileDetail /> },
        { path: "netWorthDetails", element: <NetWorthDetail /> },
        { path: "/profile/:profileId", element: <ProfileDetail /> },
        { path: "subscribeUser", element: <SubscribeUser /> },
        { path: "advertise-management", element: <AdvertiseManagment /> },
        { path: "advertise-management/add", element: <AddEditAdvertise /> },
        { path: "advertise-management/edit/:id", element: <AddEditAdvertise /> },
        { path: "netWorth", element: <NetWorth /> },
        { path: "press-release", element: <PressRelease /> },
        { path: "press-release/add", element: <AddPressRelease /> },
        { path: "press-release/edit/:id", element: <AddPressRelease /> },
        { path: "assetsDetail/:id", element: <AssetsDetail /> },
        { path: "assetsDetail/insurance/:id", element: <Insurance /> },
        { path: "assetsDetail/investment/:id", element: <Investment /> },
        { path: "assetsDetail/loans/:id", element: <Loans /> },
        {
          path: "assetsDetail/other-liabilities/add/:id",
          element: <OtherLiabilities />,
        },
        {
          path: "assetsDetail/insurance/term-life-insurance/add/:id",
          element: <TermLifeInsurance />,
        },
        {
          path: "assetsDetail/insurance/term-life-insurance/edit/:id/:insuranceId",
          element: <TermLifeInsurance />,
        },
        {
          path: "assetsDetail/insurance/long-term-insurance/add/:id",
          element: <LongTermInsurance />,
        },
        {
          path: "assetsDetail/insurance/long-term-insurance/edit/:id/:insuranceId",
          element: <LongTermInsurance />,
        },
        {
          path: "assetsDetail/insurance/individual-disability-insurance/add/:id",
          element: <IndividualDisability />,
        },
        {
          path: "assetsDetail/insurance/individual-disability-insurance/edit/:id/:insuranceId",
          element: <IndividualDisability />,
        },
        {
          path: "assetsDetail/insurance/auto-insurance/add/:id",
          element: <AutoInsurance />,
        },
        {
          path: "assetsDetail/insurance/auto-insurance/edit/:id/:insuranceId",
          element: <AutoInsurance />,
        },
        {
          path: "assetsDetail/insurance/umbrella-insurance/add/:id",
          element: <UmbrellaInsurance />,
        },
        {
          path: "assetsDetail/insurance/umbrella-insurance/edit/:id/:insuranceId",
          element: <UmbrellaInsurance />,
        },
        {
          path: "assetsDetail/insurance/whole-life-insurance/add/:id",
          element: <WholeLifeInsurance />,
        },
        {
          path: "assetsDetail/insurance/whole-life-insurance/edit/:id/:insuranceId",
          element: <WholeLifeInsurance />,
        },
        { path: "assetsDetail/real-estate/add/:id", element: <RealEstate /> },
        { path: "assetsDetail/other-assets/add/:id", element: <OtherAssets /> },
        {
          path: "assetsDetail/manual-assets/add/:id",
          element: <ManualAssets />,
        },
        { path: "/profile/notes/:id", element: <Notes /> },
        { path: "/profile/transaction/:id", element: <TransactionList /> },
        {
          path: "/profile/:userId/card-transaction/:cardId",
          element: <CardTransactionList />,
        },
        { path: "cardRequest", element: <CardRequest /> },
        { path: "loanRequest", element: <LoanRequest /> },
        { path: "transactions", element: <Transactions /> },
        { path: "adds", element: <Adds /> },
        { path: "ticketManagement", element: <TicketManagement /> },
        { path: "ticketManagement/:id", element: <TicketManagement /> },
        { path: "eKycList", element: <EkycList /> },
        { path: "eKycList/:id", element: <EkycList /> },
        { path: "settings", element: <Settings /> },
        { path: "notification", element: <Notification /> },
        {
          path: "notification/sendNotification",
          element: <SendNotification />,
        },
        { path: "tax-rate", element: <Taxrate /> },
        { path: "estate-planning", element: <EstateDocument /> },
        {
          path: "/profile/estate/last-will-and-testament/add/:id",
          element: <LastWillDocument />,
        },
        {
          path: "/profile/estate/last-will-and-testament/:actionType/:id/:userMapperId",
          element: <LastWillDocument />,
        },

        {
          path: "/profile/estate/hippa-release-and-authorization/:actionType/:id",
          element: <HippaDocument />,
        },
        {
          path: "/profile/estate/hippa-release-and-authorization/:actionType/:id/:userMapperId",
          element: <HippaDocument />,
        },
        {
          path: "/profile/estate/power-of-attorney/:actionType/:id",
          element: <PowerOfAttorneyDocument />,
        },
        {
          path: "/profile/estate/power-of-attorney/:actionType/:id/:userMapperId",
          element: <PowerOfAttorneyDocument />,
        },
        {
          path: "/profile/estate/living-will-health-care-proxy/:actionType/:id",
          element: <LivingWillDocument />,
        },
        {
          path: "/profile/estate/living-will-health-care-proxy/:actionType/:id/:userMapperId",
          element: <LivingWillDocument />,
        },
        { path: "/profile/estateDetail/:id", element: <EstateDetail /> },
        {
          path: "/estate-planning/estateDetail/:id",
          element: <EstateDetail />,
        },
        { path: "bankDetails/:id", element: <BankDetails /> },
        { path: "setGoalDetails/:id", element: <SetGoalDetails /> },
      ],
    },
  ]);

  return <Suspense fallback={null}>{routes}</Suspense>;
}
