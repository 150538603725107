import { initializeApp } from 'firebase/app';
import { getMessaging, getToken, onMessage } from 'firebase/messaging';
import { getAnalytics } from 'firebase/analytics';
import { REACT_KEY } from 'utils/appConstant';
import Cookies from 'js-cookie';

const firebaseConfig = {
  apiKey: 'AIzaSyDCnCSseW3EfEOv2zCYqBENCK5HBxff0rc',
  authDomain: 'silverback-mobile-2024.firebaseapp.com',
  projectId: 'silverback-mobile-2024',
  storageBucket: 'silverback-mobile-2024.appspot.com',
  messagingSenderId: '909618343007',
  appId: '1:909618343007:web:2a5223ea43134be562bfea',
  measurementId: 'G-XYEY9T9KZG'
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
// Analytics service
export const analytics = getAnalytics(app);
// Messaging service
export let messaging = {};
if ('serviceWorker' in navigator) {
  messaging = getMessaging(app);
}

export const requestForToken = async () => {
  if ('serviceWorker' in navigator) {
    // Initialize Messaging if needed
    // const messaging = getMessaging(app);
    // Your messaging setup code here

    // Register the service worker
    navigator.serviceWorker
      .register('/firebase-messaging-sw.js')
      .then((registration) => {
        console.log('Service Worker registered with scope:', registration.scope);
      })
      .catch((error) => {
        console.error('Service Worker registration failed:', error);
      });
  } else {
    console.warn('Service workers are not supported in this browser.');
  }

  try {
    //requesting permission using Notification API

    const permission = await Notification.requestPermission();
    if (permission === 'granted') {
      const token = await getToken(messaging, {
        vapidKey: REACT_KEY.REACT_APP_FIREBASE_VAPID_KEY
      });

      //We can send token to server
      console.log('Token generated : ', token);
      Cookies.set(REACT_KEY.FCM_TOKEN_KEY, token);
    } else if (permission === 'denied') {
      //notifications are blocked
      console.log('Please allow notification persmission to have updates to your app.');
    } else {
      console.log('Please allow notification persmission to have updates to your app.');
    }
  } catch (err) {
    console.log(err);
  }
};

export const onMessageListener = () =>
  new Promise((resolve) => {
    onMessage(messaging, (payload) => {
      console.log('payload', messaging);
      resolve(payload);
    });
  });
